@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "font-playfair";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  @apply relative z-20 max-w-screen-xl mx-auto px-5 
}
.footerLink {
  @apply hover:text-[#BBA085] text-gray-300 text-lg
}
.title{
  @apply text-lg
}